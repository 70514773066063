import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ColumnDef, Table } from '@g17eco/molecules';
import { OnboardingListExtended, OnboardingModelPlain } from '@g17eco/types/onboarding';
import { DATE, formatDate } from '@utils/date';
import { ChildrenTree } from '@g17eco/types/tree';

interface Props {
  list: OnboardingListExtended | undefined;
  initiativeMap: Map<string, ChildrenTree>;
  isOpen: boolean;
  toggle: () => void;
}

export const BulkOnboardingDetailsModal = ({ isOpen, list, initiativeMap, toggle }: Props) => {
  const onboardings = list?.onboardings ?? [];

  const columns: ColumnDef<OnboardingModelPlain>[] = [
    {
      header: 'Email',
      enableSorting: true,
      accessorFn: (onboarding) => onboarding.user.email,
    },
    {
      header: 'Companies',
      cell: ({ row }) => {
        const permissions = row.original.user.permissions;
        return !!permissions.length ? (
          <div>
            {permissions.map((permission) => (
              <p key={permission.initiativeId} className='mb-1'>{`${
                initiativeMap.get(permission.initiativeId)?.name
              }: ${permission.permissions.join(', ')}`}</p>
            ))}
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      header: 'Created',
      enableSorting: true,
      accessorFn: (onboarding) => formatDate(onboarding.created, DATE.DEFAULT_SPACES_WITH_TIME),
    },
    {
      header: 'Status',
      enableSorting: true,
      accessorFn: (onboarding) => onboarding.status,
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop='static' returnFocusAfterClose={false} size='lg'>
      <ModalHeader toggle={toggle}>Bulk onboarding details</ModalHeader>
      <ModalBody>
        <Table columns={columns} data={onboardings} pageSize={10} />
      </ModalBody>
    </Modal>
  );
};
